// App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import LandingPage from './LandingPage';
import Login from './Login';
import AdminPage from './AdminPage';
import PrivacyPage from './privacyPolicy';
import { UserProvider, useUser } from './UserContext';

function App() {
  return (
    <Router>
      <UserProvider>
        <AppRoutes />
      </UserProvider>
    </Router>
  );
}

function AppRoutes() {
  const { isLoggedIn } = useUser();

  return (
    <Routes>
      <Route path="/" element={<LandingPage />} />
      <Route path="/login" element={<Login />} />
      <Route path="/privacy-policy" element={<PrivacyPage />} />
      <Route
        path="/noukakjypella"
        element={isLoggedIn ? <AdminPage /> : <Navigate to="/login" />}
      />
    </Routes>
  );
}

export default App;
